import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <div className='parent'>
    <div className='container'>
        <div className='bodycopy'> 
            <p>Jan Podeszwa</p>
            <p>Communication Design</p>
        </div>
        <div className='bodycopy'>
            <p>Please get in touch for further information or collaboration</p>
            
        </div>
        <div className='bodycopy'>
            <p>Portfolio available on request</p>
        </div>
        <div className='bodycopy'>
            <a href="mailto:jan@podeszwa.de">→ e-mail</a>
        </div>
    </div>
    </div>
    // <Canvas
    //     className="r3f"
    //     camera={ {
    //         fov: 45,
    //         near: 0.1,
    //         far: 2000,
    //         position: [ -3, 1.5, 4 ]
    //     } }
    // >
    //     <Experience />
    // </Canvas>
    
)